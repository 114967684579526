import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="right2">   
     <div>
      <h5 className='text-warning'>About</h5>
      <h1>Evangadi Networks</h1>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur corporis quod dolorum ratione officia rem quae velit commodi. Impedit fugit nobis temporibus eos libero sed tempora ipsum, beatae hic veritatis!</p><br />
      
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur corporis quod dolorum ratione officia rem quae velit commodi. Impedit fugit nobis temporibus eos libero sed tempora ipsum, beatae hic veritatis!</p><br />
      <button className='py-2 px-2 btn text-white bg-warning'>HOW IT WORKS</button>
</div>
</div>
  )
}

export default About;